<template>
  <div v-if="organization">
    <div class="d-flex mb-2">
      <div class="flex-grow-1">
        <strong>{{ organizationName }}</strong>
      </div>
      <img
        v-if="organization.logo"
        :src="organization.logo"
        alt="Логотип организации"
        class="logo"
      />
    </div>
    <div v-if="eventIds">
      <div
        v-for="(event, idx) in eventIds"
        :key="event"
        :class="{ 'mt-3': idx !== 0 }"
      >
        <option-row title="Мероприятие:">
          {{ eventsNames[event] || "#" + event }}
        </option-row>

        <div
          v-if="
            organization && organization.events && organization.events[event]
          "
        >
          <p class="secondary--text text--lighten-2 mb-1">Наставники:</p>
          <div v-for="mentor in organization.events[event]" :key="mentor">
            <a
              href="#"
              class="link"
              @click.stop.prevent="openMentorDialog(mentor)"
            >
              <span
                v-if="
                  eventMentors[event] &&
                  eventMentors[event][mentor] &&
                  eventMentors[event][mentor].fullName
                "
                class="mb-1"
              >
                {{ eventMentors[event][mentor].fullName }}
              </span>
              <span v-else class="mb-1">
                #{{ mentor }}
                <span v-if="mentorsPending"
                  ><v-progress-circular
                    size="18"
                    width="2"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { talentClient } from "@/api";

export default {
  name: "OrganizationMentorsCard",
  props: {
    organization: {
      type: Object,
    },
  },
  data() {
    return {
      organizationTitle: "",
      eventsNames: {},
      eventMentors: {},
      mentorsPending: false,
    };
  },
  computed: {
    organizationName() {
      return this.organizationTitle
        ? this.organizationTitle
        : "#" + this.organization?.orgId;
    },
    eventIds() {
      if (!this.organization?.events) return;
      return Object.keys(this.organization.events);
    },
  },
  created() {
    this.getOrganizationName();
    this.getEvents();
    this.getMentors();
  },
  methods: {
    async getOrganizationName() {
      if (!this.organization?.orgId) return;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `/organizations/${this.organization.orgId}/`,
        });
        this.organizationTitle = data?.short_name || data?.full_name;
      } catch (error) {
        console.log("error", error);
      }
    },
    async getEvents() {
      if (!this.eventIds) return;
      try {
        const ids = this.eventIds.join(",");
        const { data } = await talentClient({
          method: "GET",
          url: `/events/`,
          params: {
            limit: 100,
            ids,
          },
        });
        if (data.count) {
          this.eventsNames = data.results.reduce((acc, item) => {
            acc[item.id] = item.alt_title || item.title;

            return acc;
          }, {});
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async getMentors() {
      if (!this.eventIds) return;
      this.mentorsPending = true;
      try {
        const results = await Promise.all(
          this.eventIds.map((item) => {
            return this.getEventMentors(
              this.organization.events[item].join(","),
              item
            );
          })
        );
        this.eventMentors = results.reduce((acc, res) => {
          acc[res.eventId] = res[res.eventId];
          return acc;
        }, {});
      } catch (error) {
        console.log("error", error);
      }
      this.mentorsPending = false;
    },
    async getEventMentors(mentorsIds, eventId) {
      try {
        const { data } = await talentClient({
          method: "GET",
          url: "/users/",
          params: {
            limit: 100,
            ids: mentorsIds,
          },
        });
        const mentorsWithFullName = data.results.reduce((acc, mentor) => {
          acc[mentor.id] = {
            ...mentor,
            eventId,
            fullName: this.getFullName(mentor),
          };
          return acc;
        }, {});
        return { [eventId]: mentorsWithFullName, eventId };
      } catch (error) {
        console.log("error", error);
        return { [eventId]: {}, eventId };
      }
    },
    getFullName(user) {
      if (!user) return "";
      return [user.last_name, user.first_name, user.middle_name]
        .filter(Boolean)
        .join(" ");
    },
    openMentorDialog(mentor) {
      if (mentor) {
        this.$emit("openMentorDialog", mentor);
      }
    },
  },
};
</script>

<style scoped></style>
