<template>
  <div>
    <contact-section
      class="mb-4"
      :total="educations.total"
      :pending="educations.pending"
      icon="mdi-school"
      title="Образование"
      :pages="educations.pagesCount"
      :current-page="educations.page"
      @setPage="setPage"
    >
      <education-card
        v-for="education in educationsList"
        :key="education.id"
        :education="education"
        class="contact-item"
        @openOrganizationDialog="openOrganizationDialog"
      />
      <div v-if="!educations.list.length && !educations.pending">
        Нет данных
      </div>
    </contact-section>

    <organization-dialog
      v-model="organizationDialog"
      :organization-id="selectedOrganization"
    />
  </div>
</template>

<script>
import ContactSection from "@/components/contact/ContactSection.vue";
import EducationCard from "@/components/contact/EducationCard.vue";
import OrganizationDialog from "@/components/dialogs/OrganizationDialog.vue";

export default {
  name: "EducationSection",
  components: { OrganizationDialog, EducationCard, ContactSection },
  props: {
    educations: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedOrganization: null,
      organizationDialog: false,
    };
  },
  computed: {
    /**
     * Сортируем список образований, так, чтобы
     * current был сверху
     */
    educationsList() {
      return [...this.educations.list].sort((a, b) => {
        return b.current - a.current;
      });
    },
  },
  methods: {
    setPage(page) {
      this.$emit("setPage", page);
    },
    openOrganizationDialog(orgId) {
      this.selectedOrganization = orgId;
      this.organizationDialog = true;
    },
  },
};
</script>
