<template>
  <div>
    <contact-section
      class="mb-4"
      :total="requests.total"
      :pending="requests.pending"
      icon="mdi-calendar"
      title="Заявки на мероприятия"
      :pages="requests.pagesCount"
      :current-page="requests.page"
      @setPage="setPage"
    >
      <template v-for="request in requests.list">
        <v-hover v-slot="{ hover }" :key="request.id">
          <request-card
            :class="{ 'on-hover': hover }"
            :request="request"
            class="contact-item"
            @click.native="openRequestDialog(request)"
            @openTeamDialog="openTeamDialog"
          />
        </v-hover>
      </template>
      <div v-if="!requests.total && !requests.pending">Нет заявок</div>
    </contact-section>

    <request-dialog
      v-model="requestDialog"
      :request="selectedRequest"
      @openTeamDialog="openTeamDialog"
      @openProjectDialog="openProjectDialog"
    />
  </div>
</template>

<script>
import RequestCard from "@/components/contact/RequestCard.vue";
import ContactSection from "@/components/contact/ContactSection.vue";
import RequestDialog from "@/components/dialogs/RequestDialog.vue";

export default {
  name: "RequestSection",
  components: { RequestDialog, ContactSection, RequestCard },
  props: {
    requests: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedRequest: null,
      requestDialog: false,
    };
  },
  methods: {
    openRequestDialog(request) {
      this.selectedRequest = request;
      this.requestDialog = true;
    },
    setPage(page) {
      this.$emit("setPage", page);
    },
    openTeamDialog(teamId) {
      this.$emit("openTeamDialog", teamId);
    },
    openProjectDialog(projectId) {
      this.$emit("openProjectDialog", projectId);
    },
  },
};
</script>
