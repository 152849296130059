<template>
  <div v-if="achievement">
    <div v-if="achievementRoleName" class="mb-2">
      <strong>{{ achievementRoleName }}</strong>
      <span v-if="eventTitle">
        в мероприятии:
        <strong>#{{ achievement.event.id }}</strong
        >&nbsp;&laquo;{{ eventTitle }}&raquo;
      </span>
    </div>
    <v-chip
      v-if="achievementStatus"
      :color="achievementStatus.color"
      small
      :outlined="achievementStatus.outlined"
      class="mr-1 mb-1"
      >{{ achievementStatus.title }}</v-chip
    >
    <v-chip v-if="achievement.team_id" small class="mr-1 mb-1"
      >Командное достижение</v-chip
    >
    <option-row
      ><span slot="title">ID Достижения:</span
      ><a
        target="User_Achievements"
        title="Перейти к достижению в админку Таланта"
        :href="`${$store.state.talentUrl}/admin/package/achievement/${achievement.id}/change/`"
        >{{ achievement.id }}</a
      ></option-row
    >
    <option-row v-if="achievement.team_id"
      ><span slot="title">ID Команды:</span>
      <a
        href="#"
        @click.prevent="$emit('openTeamDialog', achievement.team_id)"
        >{{ achievement.team_id }}</a
      >
    </option-row>
    <option-row v-if="createDate"
      ><span slot="title">Дата создания:</span>{{ createDate }}</option-row
    >
    <option-row v-if="+achievement.score"
      ><span slot="title">Баллы:</span>{{ +achievement.score }}</option-row
    >
    <option-row v-if="achievement.document"
      ><span slot="title">Подтверждающий документ:</span
      ><a download :href="achievement.document" class="link"
        >Скачать</a
      ></option-row
    >
    <option-row v-if="achievement.link"
      ><span slot="title">Ссылка на подтверждающий документ:</span
      ><a
        :href="achievement.link"
        target="_blank"
        rel="noopener noreferrer"
        class="link"
        >Перейти</a
      ></option-row
    >
    <option-row v-if="achievement.diploma_link"
      ><span slot="title">Ссылка на диплом:</span
      ><a
        :href="achievement.diploma_link"
        target="_blank"
        rel="noopener noreferrer"
        class="link"
        >Перейти</a
      ></option-row
    >
    <v-expansion-panels
      v-if="achievement.comment"
      flat
      class="black--text text-body-1 pa-0"
    >
      <v-expansion-panel @change="isOpen = !isOpen">
        <v-expansion-panel-header class="text-body-1 d-block pa-0"
          ><template #actions>
            <div class="link">
              {{ isOpen ? "Скрыть" : "Показать" }}
            </div> </template
          ><span class="secondary--text text--lighten-2 mr-1"
            >Комментарий:</span
          >
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          {{ achievement.comment }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import {
  ACHIEVEMENT_NO_STATUS,
  ACHIEVEMENT_STATUS_APPROVED,
  ACHIEVEMENT_STATUS_MODERATION,
  ACHIEVEMENT_STATUS_REJECTED,
  ACHIEVEMENT_STATUS_USER_REJECTED,
  DATE_DATABASE_FORMAT,
  DATE_DISPLAY_FORMAT,
} from "@/constants";
import dayjs from "dayjs";

export default {
  name: "AchievementCard",
  props: {
    achievement: {
      type: Object,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    createDate() {
      if (!this.achievement.created_at) return;
      return dayjs(this.achievement.created_at, DATE_DATABASE_FORMAT).format(
        DATE_DISPLAY_FORMAT
      );
    },
    achievementRoleName() {
      const { role } = this.achievement;
      return role?.name;
    },
    eventTitle() {
      const { event } = this.achievement;
      if (!event) return;
      return event.title || event.alt_title;
    },
    achievementStatus() {
      switch (this.achievement?.status) {
        case ACHIEVEMENT_STATUS_APPROVED:
          return {
            title: "Подтверждено",
            color: "primary",
          };
        case ACHIEVEMENT_STATUS_MODERATION:
          return {
            title: "На модерации",
            color: "primary",
            outlined: true,
          };
        case ACHIEVEMENT_STATUS_REJECTED:
          return {
            title: "Отклонено модератором",
            color: "error",
          };
        case ACHIEVEMENT_STATUS_USER_REJECTED:
          return {
            title: "Отклонено пользователем",
            color: "error",
          };
        case ACHIEVEMENT_NO_STATUS:
          return {
            title: "На рассмотрении пользователем",
            color: "primary",
            outlined: true,
          };
        default:
          return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-expansion-panel-header {
  min-height: inherit;
  width: max-content;
}
</style>
