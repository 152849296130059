<template>
  <div>
    <div class="mb-2">
      <strong>{{ project.title }}</strong>
    </div>
    <option-row v-if="startDate"
      ><span slot="title">Дата начала разработки:</span
      >{{ startDate }}</option-row
    >
    <option-row v-if="projectLevel"
      ><span slot="title">Уровень реализации:</span
      >{{ projectLevel }}</option-row
    >
    <option-row v-if="project.artefacts"
      ><span slot="title">Артефакты:</span
      >{{ project.artefacts.length }}</option-row
    >
    <option-row v-if="createdAt"
      ><span slot="title">Дата создания:</span>{{ createdAt }}</option-row
    >
    <option-row v-if="+project.score"
      ><span slot="title">Баллы:</span>{{ +project.score }}</option-row
    >
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  DATE_DATABASE_FORMAT,
  DATE_DISPLAY_FORMAT,
  PROJECT_LEVEL_DEMO,
  PROJECT_LEVEL_IMP,
  PROJECT_LEVEL_PROTO,
} from "@/constants";

export default {
  name: "ProjectCard",
  props: {
    project: {
      type: Object,
    },
  },
  computed: {
    startDate() {
      if (!this.project.start_date) return;
      return dayjs(this.project.start_date, DATE_DATABASE_FORMAT).format(
        DATE_DISPLAY_FORMAT
      );
    },
    createdAt() {
      if (!this.project.created_at) return;
      return dayjs(this.project.created_at, DATE_DATABASE_FORMAT).format(
        DATE_DISPLAY_FORMAT
      );
    },
    projectLevel() {
      if (!this.project.level) return;
      switch (this.project.level) {
        case PROJECT_LEVEL_DEMO:
          return "Демо";
        case PROJECT_LEVEL_PROTO:
          return "Прототип";
        case PROJECT_LEVEL_IMP:
          return "Implementation";
        default:
          return undefined;
      }
    },
  },
};
</script>
