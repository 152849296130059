<template>
  <div>
    <div class="head">
      <v-menu offset-y>
        <template #activator="{ on, attrs }">
          <v-btn color="secondary" elevation="2" v-bind="attrs" icon v-on="on"
            ><v-icon> mdi-sort </v-icon></v-btn
          >
        </template>
        <v-list>
          <v-list-item
            v-for="item in sortOptions"
            :key="item.value"
            class="list-item"
            :class="{
              active: selectedSort && selectedSort.value === item.value,
            }"
            @click="handleSort(item)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        elevation="2"
        class="f-button"
        :class="{ active: isExtraFilters }"
        @click="isExtraFilters = !isExtraFilters"
        >Фильтры</v-btn
      >
      <v-btn
        v-show="!isEmptyFilter"
        elevation="2"
        icon
        @click="$emit('clearFilters')"
        ><v-icon> mdi-delete</v-icon></v-btn
      >
    </div>
    <v-expand-transition>
      <div v-show="isExtraFilters">
        <v-row>
          <v-col md="3">
            <v-select
              v-model="selectedStatus"
              :items="statusOptions"
              label="Статус"
              item-text="title"
              item-value="value"
              return-object
            />
          </v-col>
          <v-col md="3">
            <v-select
              v-model="selectedUserStatus"
              :items="userStatusList"
              label="Роль"
              item-text="title"
              item-value="value"
              return-object
            />
          </v-col>
          <v-col md="3">
            <v-select
              v-model="selectedBrand"
              :items="brands"
              label="Бренд"
              item-text="title"
              item-value="id"
              return-object
            />
          </v-col>
          <v-col md="3">
            <v-select
              v-model="selectedSeason"
              :items="seasons"
              label="Сезон"
              item-text="title"
              item-value="value"
              return-object
            />
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import { SORT_OPTIONS, STATUS_OPTIONS } from "./constants";

export default {
  name: "AchievementsFilters",
  props: {
    sort: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      default: null,
    },
    userStatus: {
      type: String,
      default: null,
    },
    brand: {
      type: [String, Number],
      default: null,
    },
    season: {
      type: String,
      default: null,
    },
    isEmptyFilter: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isExtraFilters: false,
      selectedSort: null,
    };
  },
  computed: {
    statusOptions() {
      return STATUS_OPTIONS;
    },
    sortOptions() {
      return SORT_OPTIONS;
    },
    brands() {
      return [
        {
          title: "Все бренды",
          id: null,
        },
        ...this.$store.state.talent.brands,
      ];
    },
    seasons() {
      const years = [];
      const currentYear = new Date().getFullYear();
      for (let year = currentYear; year > currentYear - 4; year--) {
        years.push({
          title: `${year} - ${year + 1}`,
          value: String(year),
        });
      }
      return [
        {
          title: "Все сезоны",
          value: null,
        },
        ...years,
      ];
    },
    selectedStatus: {
      get() {
        return this.statusOptions.find((n) => n.value === this.status);
      },
      set(item) {
        this.$emit("setFilter", {
          key: "achStatus",
          value: item.value,
        });
      },
    },
    userStatusList() {
      const roles = this.$store.state.talent.achievementsRoles;
      const result = [
        {
          title: "Все роли",
          value: null,
        },
        ...Object.keys(roles).map((value) => {
          return {
            title: roles[value].name,
            value,
          };
        }),
      ];
      return result;
    },
    selectedUserStatus: {
      get() {
        return this.userStatusList.find((n) => n.value === this.userStatus);
      },
      set(item) {
        this.$emit("setFilter", {
          key: "achUserStatus",
          value: item.value,
        });
      },
    },
    selectedBrand: {
      get() {
        return this.brands.find((n) => n.id === this.brand);
      },
      set(item) {
        this.$emit("setFilter", {
          key: "achBrand",
          value: item.id,
        });
      },
    },
    selectedSeason: {
      get() {
        return this.seasons.find((n) => n.value === this.season);
      },
      set(item) {
        this.$emit("setFilter", {
          key: "achSeason",
          value: item.value,
        });
      },
    },
  },
  created() {
    this.getBrands();
    this.getRoles();
    this.selectedSort = this.sortOptions.find((n) => n.value === this.sort);
  },
  methods: {
    async getRoles() {
      try {
        await this.$store.dispatch("talent/getAchievementsRoles");
      } catch {
        //do nothing
      }
    },
    async getBrands() {
      try {
        await this.$store.dispatch("talent/getBrands");
      } catch {
        //do nothing
      }
    },
    handleSort(item) {
      this.selectedSort = item;
      this.$emit("setSort", item.value);
    },
  },
};
</script>

<style scoped lang="scss">
.head {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}
.f-button {
  background-color: #fff !important;
  &.active {
    background-color: var(--v-primary-base) !important;
    color: #fff !important;
  }
}

.list-item {
  &:hover {
    background-color: var(--v-secondary-lighten6);
  }
  &.active {
    background-color: var(--v-secondary-lighten5);
  }
}
</style>
