<template>
  <div>
    <div class="mb-2">
      <strong>#{{ request.event.id }} {{ request.event.title }}</strong>
    </div>
    <v-chip
      v-if="requestStatus"
      :color="requestStatus.color"
      :outlined="requestStatus.outlined"
      small
      class="mr-1 mb-1"
      >{{ requestStatus.title }}</v-chip
    >
    <option-row>
      <span slot="title">ID заявки:</span>{{ request.id }}
    </option-row>
    <option-row v-if="showRejectReason"
      ><span slot="title">Причина отклонения:</span
      >{{ request.rejection_reason }}</option-row
    >
    <option-row v-if="fullRequest && fullRequest.comment"
      ><span slot="title">Комментарии организаторов:</span
      >{{ fullRequest.comment }}</option-row
    >
    <option-row v-if="updatedAtDate"
      ><span slot="title">Дата обновления:</span>{{ updatedAtDate }}</option-row
    >
    <option-row v-if="request.team"
      ><span slot="title">Команда:</span
      ><a href="#" class="link" @click.stop.prevent="openTeamDialog">
        <span v-if="pending"
          ><v-progress-circular
            size="18"
            width="2"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </span>
        <span v-else>{{
          team && team.title ? team.title : "#" + request.team
        }}</span>
      </a></option-row
    >
  </div>
</template>

<script>
import {
  DATE_DATABASE_FORMAT,
  DATE_DISPLAY_FORMAT,
  REQUEST_STATUS_AWAIT,
  REQUEST_STATUS_CANCELED,
  REQUEST_STATUS_CONFIRMED,
  REQUEST_STATUS_REJECTED,
} from "@/constants";
import { talentClient } from "@/api";
import dayjs from "dayjs";

export default {
  name: "RequestCard",
  props: {
    request: {
      type: Object,
    },
    fullRequest: {
      type: Object,
    },
  },
  data() {
    return {
      team: null,
      pending: false,
    };
  },
  computed: {
    requestStatus() {
      const { request } = this;
      switch (request?.status) {
        case REQUEST_STATUS_CANCELED:
          return {
            color: "error",
            title: "Отклонено",
          };
        case REQUEST_STATUS_CONFIRMED:
          return {
            color: "primary",
            title: "Подтверждено",
          };
        case REQUEST_STATUS_AWAIT:
          return {
            color: "primary",
            title: "В обработке",
            outlined: true,
          };
        case REQUEST_STATUS_REJECTED:
          return {
            color: "error",
            title: "Отменена пользователем",
          };
        default:
          return null;
      }
    },
    showRejectReason() {
      const { request } = this;
      if (!request) return;
      return (
        request.rejection_reason && request.status === REQUEST_STATUS_REJECTED
      );
    },
    updatedAtDate() {
      if (!this.request?.created_at) return;
      return dayjs(this.request.created_at, DATE_DATABASE_FORMAT).format(
        DATE_DISPLAY_FORMAT
      );
    },
  },
  mounted() {
    this.getTeam();
  },
  methods: {
    openTeamDialog() {
      if (this.team && this.team.id) {
        this.$emit("openTeamDialog", this.team.id);
      }
    },
    async getTeam() {
      if (!this.request || !this.request.team) return;
      this.pending = true;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `/teams/${this.request.team}/`,
        });
        if (data.id) {
          this.team = data;
        }
      } catch (error) {
        console.log("error", error);
      }
      this.pending = false;
    },
  },
};
</script>
