<template>
  <v-card>
    <v-card-title class="pb-0 pb-md-2">
      <p class="text-h6 rm-dialog-title mb-0 mb-md-4">Разделы</p>
    </v-card-title>
    <v-card-actions>
      <div class="d-none d-md-flex action">
        <v-chip
          v-for="tab in tabs"
          :key="tab.value"
          color="primary"
          :outlined="scope !== tab.value"
          label
          class="flex-grow-1 text-center label"
          @click="$emit('handleChangeScope', tab.value)"
          >{{ tab.title }}</v-chip
        >
      </div>

      <v-select
        v-model="select"
        :items="tabs"
        item-text="title"
        item-value="value"
        label="Select"
        return-object
        single-line
        class="d-xs-block d-sm-block d-md-none px-2"
      ></v-select>
    </v-card-actions>
  </v-card>
</template>

<script>
import { CONTACTS_SCOPES } from "@/constants";

export default {
  name: "ScopeTabFilter",
  props: {
    scope: {
      type: String,
      required: true,
    },
  },
  computed: {
    tabs() {
      return Object.values(CONTACTS_SCOPES);
    },
    select: {
      get() {
        return this.tabs.find((tab) => tab.value === this.scope);
      },
      set(val) {
        this.$emit("handleChangeScope", val.value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.action {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
