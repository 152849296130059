<template>
  <div>
    <contact-section
      :total="workplaces.total"
      :pending="workplaces.pending"
      icon="mdi-domain"
      title="Место работы"
      :pages="workplaces.pagesCount"
      :current-page="workplaces.page"
      @setPage="setPage"
    >
      <div
        v-for="item in workplaces.list"
        :key="item.id"
        class="contact-item d-flex"
      >
        <div class="flex-grow-1">
          <div>
            <strong>{{ item.name }}</strong>
          </div>
          <v-chip
            v-if="item.current"
            color="primary"
            class="mb-2 mt-1"
            outlined
            small
          >
            Текущее место работы
          </v-chip>
          <option-row title="Должность">{{ item.position }}</option-row>
          <option-row title="Город/Нас. пункт">{{
            [item.settlement, item.city].filter(Boolean).join(", ")
          }}</option-row>
        </div>
        <div class="flex-grow-0 text-right" style="width: 80px">
          <v-btn
            color="grey lighten-1"
            icon
            title="Редактировать"
            @click="openWorkplaceDialog(item)"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
          <v-hover v-slot="{ hover }">
            <v-btn
              :color="hover ? 'error' : 'grey lighten-1'"
              title="Удалить место работы"
              icon
              @click.stop="$emit('handleRemoveWorkplace', item.id)"
            >
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </v-hover>
        </div>
      </div>
      <div v-if="!workplaces.list.length && !achievementsPending">
        Место работы не указано
      </div>

      <v-btn
        class="mt-3"
        color="primary"
        outlined
        small
        @click="openWorkplaceDialog(null)"
        >Добавить место работы</v-btn
      >
    </contact-section>

    <add-work-place-dialog
      v-model="workPlaceDialog"
      :talent-id="talentId"
      :workplace="selectedWorkplace"
      @onSubmit="$emit('onSubmit', 1)"
    />
  </div>
</template>

<script>
import ContactSection from "@/components/contact/ContactSection.vue";
import AddWorkPlaceDialog from "@/components/dialogs/AddWorkPlaceDialog.vue";

export default {
  name: "PlaceOfWork",
  components: { AddWorkPlaceDialog, ContactSection },
  props: {
    workplaces: {
      type: Object,
      required: true,
    },
    achievementsPending: Boolean,
  },
  data() {
    return {
      selectedWorkplace: null,
      workPlaceDialog: false,
    };
  },
  computed: {
    talentId() {
      return +this.$route.params.id;
    },
  },
  methods: {
    setPage(page) {
      this.$emit("setPage", page);
    },
    openWorkplaceDialog(workplace) {
      this.selectedWorkplace = workplace;
      this.workPlaceDialog = true;
    },
  },
};
</script>
