<template>
  <contact-section
    class="mb-4"
    :total="achievements.total"
    :pending="achievements.pending"
    icon="mdi-trophy"
    title="Достижения"
    :pages="achievements.pagesCount"
    :current-page="achievements.page"
    @setPage="setPage"
  >
    <achievements-filters
      :sort="sort"
      :user-status="userStatus"
      :status="status"
      :brand="brand"
      :season="season"
      :is-empty-filter="isEmptyFilter"
      @setSort="setSort"
      @setFilter="setFilter"
      @clearFilters="$emit('clearFilters')"
    />
    <v-divider />
    <achievement-card
      v-for="item in achievements.list"
      :key="item.id"
      :achievement="item"
      class="contact-item"
      @openTeamDialog="openTeamDialog"
    />
    <div v-if="!achievements.pending && !achievements.list.length">
      <div v-if="!isEmptyFilter" class="mb20">
        Достижений по выбранным параметрам нет. <br />
        <a
          href="#"
          class="link link--pseudo"
          @click.prevent="$emit('clearFilters')"
          >Сбросить фильтры</a
        >
      </div>
      <div v-if="isEmptyFilter">Нет достижений</div>
    </div>
  </contact-section>
</template>

<script>
import ContactSection from "@/components/contact/ContactSection.vue";
import AchievementCard from "@/components/contact/AchievementCard.vue";
import AchievementsFilters from "@/components/contact/sections/achievements/AchievementsFilters.vue";

export default {
  name: "AchievementsSection",
  components: { AchievementsFilters, AchievementCard, ContactSection },
  props: {
    achievements: {
      type: Object,
      required: true,
    },
    sort: {
      type: String,
      required: true,
    },
    userStatus: {
      type: String,
      default: null,
    },
    status: {
      type: String,
      default: null,
    },
    brand: {
      type: [String, Number],
      default: null,
    },
    season: {
      type: String,
      default: null,
    },
    isEmptyFilter: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    setPage(page) {
      this.$emit("setPage", page);
    },
    setSort(sort) {
      this.$emit("setSort", sort);
    },
    setFilter(filter) {
      this.$emit("setFilter", filter);
    },
    openTeamDialog(teamId) {
      this.$emit("openTeamDialog", teamId);
    },
  },
};
</script>
