<template>
  <v-container>
    <div class="d-md-flex d-sm-block">
      <div>
        <div class="mr-md-4 mr-xs-0 aside">
          <scope-tab-filter
            :scope="scope"
            @handleChangeScope="handleChangeScope"
          />
        </div>
      </div>

      <div class="flex-grow-1">
        <v-row v-show="scope === COMMON_SCOPE">
          <v-col lg="6" xs="12" sm="12">
            <common-section />
            <!-- Баллы по компетенциям -->
            <talent-scores
              v-if="isSuperuser && talentUser"
              :scores="scores"
              class="mb-4"
            />
            <!-- Соц. Аккаунты -->
            <social-accounts
              :socials="socials"
              :all-user-socials="allUserSocials"
              @setPage="setAccountsPage"
            />
          </v-col>
          <v-col lg="6" md="12" sm="12">
            <!-- Теги  -->
            <tags-section
              :tags="tags"
              @handleRemoveTag="handleRemoveTag"
              @handleTagClick="handleTagClick"
            />
            <!-- Комментарии  -->
            <comments-section
              :comments="comments"
              @setPage="getComments"
              @removeComment="handleRemoveComment"
              @onSuccess="getComments(1)"
            />
            <!-- Воронки -->
            <funnels-section :records="records" />
            <!-- Место работы  -->
            <place-of-work
              :workplaces="workplaces"
              :achievements-pending="achievements.pending"
              @setPage="getWorkPlaces"
              @onSubmit="getWorkPlaces"
            />
          </v-col>
        </v-row>
        <!-- Заявки  -->
        <request-section
          v-show="scope === REQUESTS_SCOPE"
          :requests="requests"
          @setPage="getRequests"
          @openTeamDialog="openTeamDialog"
          @openProjectDialog="openProjectDialog"
        />
        <!-- Достижения  -->
        <achievements-section
          v-show="scope === ACHIEVEMENTS_SCOPE"
          :achievements="achievements"
          :sort="achSort"
          :status="achStatus"
          :user-status="achUserStatus"
          :brand="achBrand"
          :season="achSeason"
          :is-empty-filter="isEmptyAchFilter"
          @setPage="getAchievements"
          @setSort="handleSetSort"
          @setFilter="handleSetFilter"
          @clearFilters="clearFilters"
          @openTeamDialog="openTeamDialog"
        />
        <!--  Наставники-->
        <mentors-section
          v-show="scope === MENTORS_SCOPE"
          :members-organizations="membersOrganizations"
          @setPage="setMembersOrganizationsPage"
        />
        <!-- Проекты -->
        <projects-section
          v-show="scope === PROJECTS_SCOPE"
          :projects="projects"
          @setPage="getProjects"
          @openProjectDialog="openProjectDialog"
        />
        <!-- Образование -->
        <education-section
          v-show="scope === EDUCATION_SCOPE"
          :educations="educations"
          @setPage="getEducations"
        />
        <!-- Организации-->
        <organizations-section
          v-show="scope === ORGANIZATIONS_SCOPE"
          :organizations="organizations"
          @setPage="getOrganizations"
        />
      </div>

      <team-dialog
        v-model="teamDialog"
        :team-id="selectedTeam"
        @openProjectDialog="openProjectDialog"
      />

      <project-dialog v-model="projectDialog" :project-id="selectedProject" />
    </div>
  </v-container>
</template>

<script>
import { apiClient, talentClient } from "@/api";
import { parseAchivement } from "@/api/utils/dataParsers";
import { initialListingModel } from "@/utils";
import TeamDialog from "@/components/dialogs/TeamDialog";
import ProjectDialog from "@/components/dialogs/ProjectDialog";
import {
  ACHIEVEMENTS_SCOPE,
  COMMON_SCOPE,
  CONTACTS_SCOPES,
  EDUCATION_SCOPE,
  MENTORS_SCOPE,
  ORGANIZATIONS_SCOPE,
  PROJECTS_SCOPE,
  REQUESTS_SCOPE,
  SOCIAL_PROVIDERS_SLUGS,
} from "@/constants";
import TalentScores from "@/components/contact/TalentScores.vue";
import CommonSection from "@/components/contact/sections/CommonSection.vue";
import SocialAccounts from "@/components/contact/sections/SocialAccounts.vue";
import PlaceOfWork from "@/components/contact/sections/PlaceOfWork.vue";
import RequestSection from "@/components/contact/sections/RequestSection.vue";
import AchievementsSection from "@/components/contact/sections/achievements/AchievementsSection.vue";
import MentorsSection from "@/components/contact/sections/MentorsSection.vue";
import TagsSection from "@/components/contact/sections/TagsSection.vue";
import CommentsSection from "@/components/contact/sections/CommentsSection.vue";
import FunnelsSection from "@/components/contact/sections/FunnelsSection.vue";
import ProjectsSection from "@/components/contact/sections/ProjectsSection.vue";
import EducationSection from "@/components/contact/sections/EducationSection.vue";
import OrganizationsSection from "@/components/contact/sections/OrganizationsSection.vue";
import ScopeTabFilter from "@/components/contact/ScopeTabFilter.vue";
import dayjs from "@/plugins/dayjs";

const initialData = () => {
  return {
    records: [],
    tags: [],
    requests: initialListingModel(15, { ordering: "-updated_at" }),
    achievements: initialListingModel(15, { ordering: "-created_at" }),
    educations: initialListingModel(10, { ordering: "-updated_at" }),
    projects: initialListingModel(10),
    workplaces: initialListingModel(10),
    organizations: initialListingModel(10),
    membersOrganizations: initialListingModel(10),
    socials: {
      ...initialListingModel(10),
      telegramAccounts: [],
      kaggleAccounts: [],
    },
    comments: initialListingModel(5),
    comment: "",
    teamDialog: false,
    selectedTeam: null,
    selectedProject: null,
    projectDialog: false,
    scores: [],
    isInitialized: false,
  };
};
export default {
  name: "Contact",
  components: {
    TalentScores,
    ScopeTabFilter,
    OrganizationsSection,
    EducationSection,
    ProjectsSection,
    FunnelsSection,
    CommentsSection,
    TagsSection,
    MentorsSection,
    AchievementsSection,
    RequestSection,
    PlaceOfWork,
    SocialAccounts,
    CommonSection,
    ProjectDialog,
    TeamDialog,
  },
  data() {
    return {
      ...initialData(),
      scope: "",
      achSort: "-created_at",
      achStatus: null,
      achUserStatus: null,
      achBrand: null,
      achSeason: null,
    };
  },
  metaInfo() {
    return {
      title: `Карточка контакта`,
    };
  },
  computed: {
    isSuperuser() {
      return this.$store.getters["user/isSuperuser"];
    },
    talentId() {
      return +this.$route.params.id;
    },
    allUserSocials() {
      const { list, telegramAccounts, kaggleAccounts } = this.socials;
      return [...list, ...telegramAccounts, ...kaggleAccounts];
    },
    isEmptyAchFilter() {
      return !(
        this.achUserStatus ||
        this.achStatus ||
        this.achBrand ||
        this.achSeason
      );
    },
    talentUser() {
      return this.$store.state.talent.users[this.$route.params.id];
    },
  },
  watch: {
    $route(to, from) {
      if (to.params.id !== from.params.id) {
        Object.assign(this.$data, initialData());
        this.init();
      }
    },
    "$route.query.scope": {
      handler(scope) {
        if (scope) {
          this.scope = scope;
        } else {
          this.scope = COMMON_SCOPE;
        }
      },
    },
  },
  created() {
    this.COMMON_SCOPE = COMMON_SCOPE;
    this.REQUESTS_SCOPE = REQUESTS_SCOPE;
    this.PROJECTS_SCOPE = PROJECTS_SCOPE;
    this.ACHIEVEMENTS_SCOPE = ACHIEVEMENTS_SCOPE;
    this.EDUCATION_SCOPE = EDUCATION_SCOPE;
    this.ORGANIZATIONS_SCOPE = ORGANIZATIONS_SCOPE;
    this.MENTORS_SCOPE = MENTORS_SCOPE;
    this.CONTACTS_SCOPES = CONTACTS_SCOPES;
    this.init();
    if (this.$route.query.scope) {
      this.scope = this.$route.query.scope;
    } else {
      this.scope = COMMON_SCOPE;
    }
  },
  methods: {
    handleChangeScope(scope) {
      if (this.scope === scope) return;
      this.$router.push({ query: { scope } });
    },
    async getMentors() {
      const { membersOrganizations, talentId } = this;
      membersOrganizations.pending = true;
      const limit = 50;
      let communications = [];

      const getCommunications = async (offset = 0) => {
        try {
          const { data } = await talentClient({
            method: "GET",
            url: `/users/${talentId}/organizations/members/`,
            params: {
              type: "student",
              limit,
              offset,
            },
          });

          if (data.count === 0) return;

          communications = [...communications, ...data.results];

          if (data.results.length < limit) {
            const newList = communications.reduce((acc, item) => {
              if (item.mentor && item.event) {
                if (!acc[item.organization]) {
                  acc[item.organization] = {};
                  acc[item.organization].events = {};
                  acc[item.organization].events[item.event] = [item.mentor];
                  acc[item.organization].orgId = item.organization;
                } else {
                  if (!acc[item.organization]?.events[item.event]) {
                    acc[item.organization].events[item.event] = [item.mentor];
                  } else {
                    acc[item.organization].events[item.event].push(item.mentor);
                  }
                }
              }
              return acc;
            }, {});
            const list = Object.values(newList);
            membersOrganizations.total = list.length;
            membersOrganizations.list = list;
            return;
          }

          await getCommunications(offset + limit);
        } catch (error) {
          membersOrganizations.error = error.message;
        }
      };

      await getCommunications();
      membersOrganizations.pending = false;
    },
    setAccountsPage(page) {
      this.socials.page = page;
    },
    setMembersOrganizationsPage(page) {
      this.membersOrganizations.page = page;
    },
    async getListingModel(modelName, url, page = 1, params) {
      const model = this[modelName];
      if (!model || model.pending) return;
      model.error = "";
      model.pending = true;
      try {
        const { data } = await talentClient({
          method: "GET",
          url,
          params: {
            limit: model.limit,
            offset: (page - 1) * model.limit,
            ...(model.params && {
              ...model.params,
            }),
            ...(params && {
              ...params,
            }),
          },
        });
        model.total = data.count;
        if (modelName === "achievements") {
          model.list = data.results.map(parseAchivement);
        } else {
          model.list = data.results;
        }
        model.pagesCount = Math.ceil(data.count / model.limit);
        model.page = page;
      } catch (error) {
        model.error = error.message;
      }
      model.pending = false;
    },
    async getRequests(page = 1) {
      const { talentId } = this;
      const url = `/users/${talentId}/requests/`;
      await this.getListingModel("requests", url, page);
    },
    async getEducations(page = 1) {
      const { talentId } = this;
      const url = `/users/${talentId}/educations/`;
      await this.getListingModel("educations", url, page);
    },
    async getOrganizations(page = 1) {
      const url = `users/${this.talentId}/organizations/`;
      await this.getListingModel("organizations", url, page);
    },
    async getAchievements(page = 1) {
      const { achSort, achStatus, achUserStatus, achBrand, achSeason } = this;
      const params = {
        ...this["achievements"].params,
        ordering: achSort,
        ...(achStatus && { status: achStatus }),
        ...(achUserStatus && { roles: achUserStatus }),
        ...(achBrand && { brands: achBrand }),
      };

      if (achSort.includes("event__title")) {
        params.ordering = `${achSort},created_at`;
      }

      if (achSeason) {
        params.start_after = dayjs(`${achSeason}-01-01`).format();
        params.end_before = dayjs(`${+achSeason + 2}-01-01`).format();
      }

      const { talentId } = this;
      const url = `/users/${talentId}/achievements/`;
      await this.getListingModel("achievements", url, page, params);
    },
    async getProjects(page = 1) {
      const { talentId } = this;
      const url = `/users/${talentId}/projects/`;
      await this.getListingModel("projects", url, page);
    },
    async getComments(page = 1) {
      const { comments } = this;
      if (comments.pending) return;
      comments.error = "";
      comments.pending = true;
      comments.page = page;
      try {
        const { data } = await apiClient({
          method: "GET",
          url: `/contacts/${this.talentId}/comments`,
          params: {
            limit: comments.limit,
            offset: (comments.page - 1) * comments.limit,
          },
        });
        comments.list = data.results;
        comments.total = data.count;
        comments.pagesCount = Math.ceil(data.count / comments.limit);
      } catch (error) {
        comments.error = error.message;
      }
      comments.pending = false;
    },
    async getWorkPlaces(page = 1) {
      const { workplaces } = this;
      if (workplaces.pending) return;
      workplaces.error = "";
      workplaces.pending = true;
      workplaces.page = page;
      try {
        const { data } = await apiClient({
          method: "GET",
          url: `/contacts/${this.talentId}/workplaces`,
          params: {
            limit: workplaces.limit,
            offset: (workplaces.page - 1) * workplaces.limit,
          },
        });
        workplaces.list = data.results;
        workplaces.total = data.count;
        workplaces.pagesCount = Math.ceil(data.count / workplaces.limit);
      } catch (error) {
        workplaces.error = error.message;
      }
      workplaces.pending = false;
    },
    async handleRemoveComment(id) {
      try {
        await apiClient({
          method: "DELETE",
          url: `/contacts/${this.talentId}/comments/${id}`,
        });
        this.getComments(this.comments.page);
      } catch (error) {
        this._showError(`Не удалось удалить комментарий. ${error.message}`);
      }
    },
    async getSocialAccounts(provider) {
      const { talentId } = this;
      const url = provider
        ? `/users/${talentId}/social-auths/${provider}/`
        : `/users/${talentId}/social-auths/`;
      const accountDataName = provider ? provider + "Accounts" : "list";
      const { data } = await talentClient({
        method: "GET",
        url: url,
      });
      if (data.count) {
        if (provider) {
          this.socials[accountDataName] = data.results.map((item) => {
            return {
              ...item,
              provider,
            };
          });
        } else {
          this.socials[accountDataName] = data.results;
        }
      }
    },
    async getAllUserSocialAccounts() {
      this.socials.pending = true;
      try {
        await Promise.all([
          this.getSocialAccounts(),
          this.getSocialAccounts(SOCIAL_PROVIDERS_SLUGS.telegram),
          this.getSocialAccounts(SOCIAL_PROVIDERS_SLUGS.kaggle),
        ]);
      } catch (error) {
        this.socials.error = error.message;
        console.log(error);
      }
      this.socials.pending = false;
    },
    /**
     * Получение информации о баллах за конкурс таланта
     * доступно только суперюзерам
     */
    async getTalentScores() {
      if (!this.isSuperuser) return;
      try {
        const { data } = await talentClient({
          baseURL: "/calcon",
          url: "/scores",
          params: {
            user_id: this.talentId,
            hide_zeros: true,
          },
        });

        this.scores = data.results;
      } catch (error) {
        this.$toast(
          `Не удалось получить баллы пользователя за конкурс Талант. ${error.message}`,
          {
            type: "error",
          }
        );
      }
    },
    async init() {
      // ресетим инитиал дату
      if (this.isInitialized) {
        Object.assign(this.$data, initialData());
      }
      this.getContact();
      try {
        await this.getTalentUser();
        this.getRequests();
        this.getAchievements();
        this.getEducations();
        this.getProjects();
        this.getAllUserSocialAccounts();
        this.getComments();
        this.getWorkPlaces();
        this.getOrganizations();
        this.getMentors();
        this.getTalentScores();
        this.isInitialized = true;
      } catch (error) {
        console.log("error", error);
      }
    },
    async getContact() {
      const { data } = await apiClient({
        method: "GET",
        url: `/contacts/${this.talentId}`,
      });
      this.records = data.Records;
      this.tags = data.tags;
    },
    async getTalentUser() {
      await this.$store.dispatch("talent/getUserById", +this.$route.params.id);
    },
    async handleTagClick(tag) {
      const { data } = await apiClient({
        method: "POST",
        url: `/contacts/${this.talentId}/tags`,
        data: { tags: [tag.id] },
      });
      this.tags.push(tag);
      console.log("data", data);
    },
    async handleRemoveTag(tag) {
      //  Спрашиваем
      const confirm = await this.$root.$confirm(
        "Удалить тег у контакта",
        `Вы действительно хотите удалить тег &laquo;${tag.name}&raquo; у контакта?`,
        {
          confirmText: "Да, удалить",
          rejectText: "Нет, отмена",
        }
      );

      if (!confirm) return;

      if (confirm) {
        try {
          // удаляем
          await apiClient({
            method: "DELETE",
            url: `/contacts/${this.talentId}/tags`,
            data: { tags: [tag.id] },
          });
          const idx = this.tags.findIndex((n) => n.id === tag.id);
          // обновляем локальный стейт, если все оки
          this.$delete(this.tags, idx);
        } catch (error) {
          this._showError("Не удалось удалить тег, возникла ошибка");
        }
      }
    },
    async handleRemoveWorkplace(workplace_id) {
      const confirm = await this.$root.$confirm(
        "Удалить место работы",
        `Вы действительно хотите удалить это место работы у контакта?`,
        {
          confirmText: "Да, удалить",
          rejectText: "Нет, отмена",
        }
      );
      if (confirm) {
        try {
          await apiClient({
            method: "DELETE",
            url: `/contacts/${this.talentId}/workplaces/${workplace_id}`,
          });
          this.getWorkPlaces();
          this.$toast("Аватар удален", { type: "success" });
        } catch (error) {
          this._showError(`Не удалось удалить место работы. ${error.message}`);
        }
      }
    },
    openTeamDialog(teamId) {
      this.selectedTeam = teamId;
      this.teamDialog = true;
    },
    openProjectDialog(projectId) {
      this.selectedProject = projectId;
      this.projectDialog = true;
    },
    handleSetSort(sort) {
      this.achSort = sort;
      this.getAchievements(1);
    },
    handleSetFilter(filter) {
      if (filter) this[filter.key] = filter.value;
      this.getAchievements(1);
    },
    clearFilters() {
      this.achUserStatus = null;
      this.achSeason = null;
      this.achStatus = null;
      this.achBrand = null;
      this.getAchievements(1);
    },
  },
};
</script>

<style lang="scss">
.contact-item {
  border-bottom: 1px solid #c0c0c0;
  padding-bottom: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }
  &.on-hover {
    background-color: #eee;
    cursor: pointer;
  }
}

.search-container {
  position: sticky;
  top: 60px;
  z-index: 1;
  width: 250px;
  margin-top: 4px;

  @media screen and (max-width: 961px) {
    width: 100%;
    position: static;
  }
}
.aside {
  position: sticky;
  top: 60px;
  z-index: 1;
  width: 100%;
  max-width: 200px;
  @media screen and (max-width: 959px) {
    max-width: 100%;
    margin-bottom: 20px;
    position: static;
  }
}

.label {
  width: 100%;
}
</style>
