<template>
  <div>
    <v-card class="mb-4">
      <v-toolbar color="primary" dark dense flat>
        <v-toolbar-title class="white--text">Общая информация</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="black--text text-body-1 pa-4">
        <contact-info :talent-user="talentUser" />
      </v-card-text>
      <v-card-actions class="pb-4 px-4 pt-0">
        <v-btn color="primary" class="mr-2" @click="userDialog = true"
          >Редактировать</v-btn
        >
        <v-btn
          v-if="talentUser && talentUser.avatar"
          color="primary"
          text
          @click="handleRemoveAvatar"
          >Удалить аватар</v-btn
        >
      </v-card-actions>
    </v-card>

    <edit-user-dialog
      v-model="userDialog"
      :talent-user="talentUser"
    ></edit-user-dialog>
  </div>
</template>

<script>
import ContactInfo from "@/components/contact/ContactInfo.vue";
import EditUserDialog from "@/components/dialogs/EditUserDialog.vue";
import { talentClient } from "@/api";

export default {
  name: "CommonSection",
  components: { EditUserDialog, ContactInfo },
  data() {
    return {
      userDialog: false,
    };
  },
  computed: {
    talentUser() {
      return this.$store.state.talent.users[this.$route.params.id];
    },
  },
  methods: {
    async handleRemoveAvatar() {
      if (!this.talentUser?.avatar) return;
      const confirm = await this.$root.$confirm(
        "Удалить аватар",
        `Удаляя аватар пользователя, вы подтверждаете, что данное изображение содержит информацию, не отвечающую требованиям пользовательского соглашения платформы Талант.<br /> В ином случае, этого делать <strong>запрещено</strong>!`,
        {
          confirmText: "Да, удалить",
          rejectText: "Нет, отмена",
        }
      );
      if (confirm) {
        try {
          const { data } = await talentClient({
            method: "PATCH",
            url: `/users/${this.talentUser?.id}/`,
            data: {
              avatar: null,
            },
          });
          if (data.id) {
            this.$store.commit("talent/UPDATE_USER", data);
          }
          this.$toast("Аватар удален", { type: "success" });
        } catch (error) {
          this._showError(`Не удалось удалить аватар. ${error.message}`);
        }
      }
    },
  },
};
</script>
