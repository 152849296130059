var render = function render(){var _vm=this,_c=_vm._self._c;return _c('contact-section',{staticClass:"mb-4",attrs:{"total":_vm.tags.length,"pending":false,"icon":"mdi-tag","title":"Теги","pages":1,"current-page":1},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('v-divider'),_c('v-card-text',{staticClass:"black--text text-body-1 pa-4"},[_c('tag-search',{attrs:{"selected-tags":_vm.tags},on:{"tagClick":_vm.handleTagClick}})],1)]},proxy:true}])},[_vm._l((_vm.tags),function(item){return _c('v-chip',{key:item.id,staticClass:"mr-2 mb-2",attrs:{"color":item.color,"dark":"","small":"","title":`Показать всех пользователей с тегом «${item.name}»`},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
        name: 'search',
        query: {
          tags_ids: item.id,
          search: '',
        },
      })}}},[_vm._v(" "+_vm._s(item.name)+" "),_c('v-icon',{attrs:{"right":"","size":"18px","title":"Удалить тег"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('handleRemoveTag', item)}}},[_vm._v("mdi-close")])],1)}),(!_vm.tags.length)?_c('div',[_vm._v("Нет тегов")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }