<template>
  <contact-section
    class="mb-4"
    :total="allUserSocials.length"
    :pending="socials.pending"
    icon="mdi-account-box"
    title="Социальные аккаунты"
    :pages="socialPageCount"
    :current-page="socials.page"
    @setPage="setPage"
  >
    <social-card
      v-for="social in displayedUserSocials"
      :key="social.id"
      :social="social"
      class="contact-item"
    />

    <div v-if="!allUserSocials.length && !socials.pending">
      Нет связанных социальных аккаунтов
    </div>
  </contact-section>
</template>

<script>
import ContactSection from "@/components/contact/ContactSection.vue";
import SocialCard from "@/components/contact/SocialCard.vue";

export default {
  name: "SocialAccounts",
  components: { SocialCard, ContactSection },
  props: {
    socials: {
      type: Object,
      required: true,
    },
    allUserSocials: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    displayedUserSocials() {
      const start = (this.socials.page - 1) * this.socials.limit;
      const end = start + this.socials.limit;
      return this.allUserSocials.slice(start, end);
    },
    socialPageCount() {
      return Math.ceil(this.allUserSocials.length / this.socials.limit);
    },
  },
  methods: {
    setPage(page) {
      this.$emit("setPage", page);
    },
  },
};
</script>
